


















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { GET_DETAILS } from '@/modules/conservatorship/store/getters';
import { FETCH_DETAILS } from '@/modules/conservatorship/store/actions';
import { ConservatorshipDetails, FetchDetails } from '@/modules/conservatorship/types';
import { ApiResponse } from '@/components/types';
import { createReport } from '../model';
import { addDays, format, getYear, setYear, startOfYear, subYears } from '@/filter/dateFormat';
import { ReportFinances, ReportOfConservatorshipManagement } from '@/modules/reportOfConservatorshipManagement/types';
import { CLEAR_CREATED, GET_CREATED, SET_CREATED } from '@/modules/reportOfConservatorshipManagement/store';
import { AxiosResponse } from 'axios';
import clone from 'clone';
import Stepper1 from '../components/createStepper/Stepper1.vue';
import Stepper2 from '../components/createStepper/Stepper2.vue';
import Stepper3 from '../components/createStepper/Stepper3.vue';
import Stepper4 from '../components/createStepper/Stepper4.vue';
import Stepper5 from '../components/createStepper/Stepper5.vue';
import Stepper6 from '../components/createStepper/Stepper6.vue';
import Stepper7 from '../components/createStepper/Stepper7.vue';
import Stepper8 from '../components/createStepper/Stepper8.vue';
import Stepper9 from '../components/createStepper/Stepper9.vue';
import { routeToLocation } from '@/router';
import uuid from 'uuid/v4';

const Conservatorship = namespace('conservatorship');
const Report = namespace('reportOfConservatorshipManagement');

@Component({
  components: { Stepper9, Stepper8, Stepper7, Stepper6, Stepper5, Stepper4, Stepper3, Stepper2, Stepper1 }
})
export default class ReportBuilder extends Vue {
  @Conservatorship.Action(FETCH_DETAILS) fetchDetails!: (params: FetchDetails) => Promise<ApiResponse>;
  @Conservatorship.Getter(GET_DETAILS) getDetails!: (id: string) => ConservatorshipDetails | undefined;

  @Report.Getter(GET_CREATED) getReport!: (conservatorship_id: string) => ReportOfConservatorshipManagement | undefined;
  @Report.Mutation(SET_CREATED) setReport!: ({ conservatorship_id, report }: { conservatorship_id: string, report: ReportOfConservatorshipManagement }) => void;
  @Report.Mutation(CLEAR_CREATED) clearReport!: ({ conservatorship_id }: { conservatorship_id: string }) => void;

  get conservatorship() {
    return this.getDetails(this.$route.params['id']);
  }

  steps: Array<{ step: number, label: string }> = [
    { step: 1, label: 'Kopfdaten' },
    { step: 2, label: 'Nr. 1 - 3' },
    { step: 3, label: 'Nr. 4 - 7' },
    { step: 4, label: 'Nr. 8 - 9' },
    { step: 5, label: 'Nr. 10 - 11' },
    { step: 6, label: 'Nr. 12' },
    { step: 7, label: 'Nr. 13' },
    { step: 8, label: 'Nr. 14 - 15' },
    { step: 9, label: 'Zusammenfassung' },
  ];

  report = createReport(uuid());
  reportFinances: ReportFinances | null = null;
  januaryFinances: ReportFinances | null = null;
  loading: boolean = true;

  @Watch('report', { deep: true })
  onReport(report: ReportOfConservatorshipManagement) {
    this.setReport({ conservatorship_id: this.$route.params['id'], report });
  }

  get stepper(): number {
    return parseInt(this.$route.query['stepper'] as string, 10) || 1;
  }

  set stepper(stepper: number) {
    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, stepper: (stepper || 1).toString() } })).catch(() => {});
  }

  @Watch('$route.params.id', { immediate: true })
  async onIdChange(id: string) {
    const { error } = await this.fetchDetails({ id });

    if (!error) {
      return;
    }

    return this.$router.push({ name: 'conservatorship-list' }).catch(() => {});
  }

  @Watch('conservatorship', { immediate: true })
  async onConservatorship(conservatorship: ConservatorshipDetails) {
    if (!conservatorship) return;

    const report = this.getReport(conservatorship.id);

    if (report) {
      this.report = clone(report, false);

    } else {
      try {
        const response = await this.$http.get(`api/conservatorship-management/${this.$route.params['id']}/report/last`, { responseType: 'json' });
        this.report = response.data;
      } catch {
      }

      const thisYear = getYear(Date.now());

      const end = format(setYear(conservatorship.assigned, thisYear), 'yyyy-MM-dd');
      const start = format(addDays(subYears(end, 1), 1), 'yyyy-MM-dd');

      this.report.start = start;
      this.report.end = end;
      this.report.report_id = uuid()
    }

    this.report.financial_administration = conservatorship.hasFinancialAdministration;

    await this.$http
      .get(`api/conservatorship-management/${conservatorship.id}/report-information/finance-account-balances`, { params: { date: this.report.end } })
      .then((response: AxiosResponse<ReportFinances>) => {
        this.reportFinances = response.data;
      })
      .catch((error) => {
        console.error(error);
      });

    await this.$http
      .get(`api/conservatorship-management/${conservatorship.id}/report-information/finance-account-balances`, { params: { date: format(startOfYear(this.report.end), 'yyyy-MM-dd') } })
      .then((response: AxiosResponse<ReportFinances>) => {
        this.januaryFinances = response.data;
      })
      .catch((error) => {
        console.error(error);
      });

    setTimeout(() => {
      this.loading = false;
    }, 200);
  }

  back() {
    this.clearReport({ conservatorship_id: this.$route.params['id'] });
    this.$router.push({
      name: 'conservatorship-details',
      params: { id: this.$route.params['id'] },
      query: { active: 'tab-reports' }
    }).catch(() => {});
  }
}
