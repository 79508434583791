






























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ConservatorshipDetails } from '@/modules/conservatorship/types';
import { ReportOfConservatorshipManagement } from '@/modules/reportOfConservatorshipManagement/types';
import { AxiosResponse } from 'axios';
import { Contact } from '@/modules/contact/types';
import { baseURL } from '@/modules/contact/store';
import DatePicker from '@/components/form/DatePicker.vue';

interface CompanyResponse {
  name: string;
  street: string;
  zipCode: string;
  city: string;
  phoneNumber: string;
}

@Component({
  components: { DatePicker }
})
export default class Stepper1 extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;
  @Prop({ type: Object, required: true }) report!: ReportOfConservatorshipManagement;

  value: ReportOfConservatorshipManagement | null = null;

  created() {
    if (!this.report.conservator.name) {
      this.report.conservator.name = this.conservatorship.conservator.name;
    }

    if (!this.report.conservatee.name) {
      this.report.conservatee.name = `${this.conservatorship.conservatee.forename} ${this.conservatorship.conservatee.surname}`;
      this.report.conservatee.birthday = this.conservatorship.conservatee.birthday;
      this.report.conservatee.attorney_docket = this.conservatorship.attorneyDocket;
    }

    if (!this.report.address.name) {
      this.$http.get(`api/contact/company`)
        .then((response: AxiosResponse<CompanyResponse>) => {
          this.report.address.name = response.data.name;
          this.report.address.street = response.data.street;
          this.report.address.city = response.data.city;
          this.report.address.zip_code = response.data.zipCode;

          this.report.conservator.phone_number = response.data.phoneNumber;
        });
    }

    if (!this.report.court.name) {
      this.$http.get(`${baseURL()}/contact-service/contact/${this.conservatorship.court.id}`)
        .then((response: AxiosResponse<Contact>) => {
          this.report.court.name = response.data.name || '';
          this.report.court.street = response.data.street || '';
          this.report.court.city = response.data.city || '';
          this.report.court.zip_code = response.data.zipCode || '';
        });
    }
  }
}
