



































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ConservatorshipDetails } from '@/modules/conservatorship/types';
import { ReportOfConservatorshipManagement } from '@/modules/reportOfConservatorshipManagement/types';

@Component({})
export default class Stepper8 extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;
  @Prop({ type: Object, required: true }) report!: ReportOfConservatorshipManagement;

  removeLegalProceeding(asset: string) {
    this.report.economic_circumstances.legal_proceedings = [
      ...this.report.economic_circumstances.legal_proceedings.filter((newAsset) => newAsset !== asset)
    ];
  }

  addLegalProceeding() {
    this.report.economic_circumstances.legal_proceedings = [
      ...this.report.economic_circumstances.legal_proceedings,
      ''
    ];
  }
}
