




























































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ConservatorshipDetails } from '@/modules/conservatorship/types';
import { ReportOfConservatorshipManagement } from '@/modules/reportOfConservatorshipManagement/types';
import uuid from 'uuid/v4';

@Component({})
export default class Stepper5 extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;
  @Prop({ type: Object, required: true }) report!: ReportOfConservatorshipManagement;

  removeSubsistence({ id }: { name: string, cost: number, id: string }) {
    this.report.economic_circumstances.revenue_usage.subsistence_parts = [
      ...this.report.economic_circumstances.revenue_usage.subsistence_parts.filter((subsistence) => subsistence.id !== id)
    ];
  }

  addSubsistence() {
    this.report.economic_circumstances.revenue_usage.subsistence_parts = [
      ...this.report.economic_circumstances.revenue_usage.subsistence_parts,
      { name: '', cost: 0, id: uuid() }
    ];
  }

  removePayable({ id }: { name: string, cost: number, id: string }) {
    this.report.economic_circumstances.revenue_usage.payables = [
      ...this.report.economic_circumstances.revenue_usage.payables.filter((payable) => payable.id !== id)
    ];
  }

  addPayable() {
    this.report.economic_circumstances.revenue_usage.payables = [
      ...this.report.economic_circumstances.revenue_usage.payables,
      { name: '', cost: 0, id: uuid() }
    ];
  }

  removeOther({ id }: { name: string, cost: number, id: string }) {
    this.report.economic_circumstances.revenue_usage.other_parts = [
      ...this.report.economic_circumstances.revenue_usage.other_parts.filter((payable) => payable.id !== id)
    ];
  }

  addOther() {
    this.report.economic_circumstances.revenue_usage.other_parts = [
      ...this.report.economic_circumstances.revenue_usage.other_parts,
      { name: '', cost: 0, id: uuid() }
    ];
  }

  removeAsset(asset: string) {
    this.report.economic_circumstances.new_assets = [
      ...this.report.economic_circumstances.new_assets.filter((newAsset) => newAsset !== asset)
    ];
  }

  addAsset() {
    this.report.economic_circumstances.new_assets = [
      ...this.report.economic_circumstances.new_assets,
      ''
    ];
  }
}
