



































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ConservatorshipDetails } from '@/modules/conservatorship/types';
import {
  FinanceAccount,
  ReportFinances,
  ReportOfConservatorshipManagement
} from '@/modules/reportOfConservatorshipManagement/types';
import DatePicker from '@/components/form/DatePicker.vue';
import uuid from 'uuid/v4';

@Component({
  components: { DatePicker }
})
export default class Stepper4 extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;
  @Prop({ type: Object, required: true }) report!: ReportOfConservatorshipManagement;
  @Prop({ type: Object }) finances?: ReportFinances;

  removeAsset(index: number) {
    this.report.economic_circumstances.revenue.assets.splice(index, 1)
  }

  addAsset() {
    this.report.economic_circumstances.revenue.assets = [
      ...this.report.economic_circumstances.revenue.assets,
      { name: '', balance: 0, id: uuid(), type: 'Sonstiges Einkommen' }
    ];
  }

  selectAllowance(account: FinanceAccount) {
    this.report.economic_circumstances.placement_cost.date = this.report.end;
    this.report.economic_circumstances.placement_cost.balance = parseFloat(account.balance);
  }

  get accounts() {
    if (!this.finances) return [];

    return this.finances.accounts
      .filter((account) => account.category === 'bank_account')
      .map((account) => `${account.number} (${account.name})`);
  }

  get allowances() {
    if (!this.finances) return [];

    return this.finances.accounts
      .filter((account) => account.category === 'cash_account' && account.type === 'other_account')
      .map((account) => ({ ...account, name: `${account.name} (${account.balance} €)` }));
  }

  @Watch('finances', { immediate: true })
  onFinances(finances?: ReportFinances) {
    if (this.report.economic_circumstances.revenue.assets.length > 0 || !finances) {
      return;
    }

    if (finances.revenues.length > 0) {
      this.report.economic_circumstances.revenue.assets = finances.revenues
        .map(({ type, amount, ...revenue }) => {
          return { ...revenue, balance: amount, type: this.$i18n.t(`listOfAssets.groups.${type}`) as string };
        });

      return;
    }

    this.report.economic_circumstances.revenue.assets = [
      { name: '', balance: 0, id: uuid(), type: this.$i18n.t(`listOfAssets.groups.bonds`) as string },
      { name: '', balance: 0, id: uuid(), type: this.$i18n.t(`listOfAssets.groups.sick_benefit`) as string },
      { name: '', balance: 0, id: uuid(), type: this.$i18n.t(`listOfAssets.groups.welfare`) as string },
      { name: '', balance: 0, id: uuid(), type: this.$i18n.t(`listOfAssets.groups.earned_income`) as string }
    ];
  }
}
